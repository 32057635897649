import Landing from "./scenes/Landing";
import { useContext } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import PhotosUpload from "./scenes/PhotosUpload";
import PhotoGallery from "./scenes/PhotoGallery";
import LoginPage from "./scenes/LoginPage";
import { AuthContext } from "./context/AuthContext";
import Nature from "./scenes/galleries/Nature";
import People from "./scenes/galleries/People";
import Urban from "./scenes/galleries/Urban";
import PageNotFound from "./scenes/PageNotFound";
import AllPhotos from "./scenes/galleries/AllPhotos";
import Reel from "./scenes/Reel";

function App() {
  const { currentUser } = useContext(AuthContext);
  const RequareAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/reel" element={<Reel />} />
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/photo-gallery" element={<PhotoGallery />}>
            <Route path="all" element={<AllPhotos />} />
            <Route path="nature" element={<Nature />} />
            <Route path="people" element={<People />} />
            <Route path="urban" element={<Urban />} />
          </Route>
          <Route
            path="/upload-photos"
            element={
              <RequareAuth>
                <PhotosUpload />
              </RequareAuth>
            }
          />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
