import React from "react";
import ReactPlayer from "react-player/vimeo";

function Reel() {
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <ReactPlayer
        width="100vw"
        height="100vh"
        url="https://vimeo.com/1025804616"
        controls
      />
    </div>
  );
}

export default Reel;
